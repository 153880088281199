<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron registros de tiempo trabajado"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :toolbar-options="toolbarOptions"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="tiempo_trabajado.idtiempo_trabajado"
      @click-item="clickListItem"
      @click-toolbar-option="clickToolbarOption"
    >
      <template
        #listItem="slotProps"
      >
        <tiempo-trabajado-list-item
          :item="slotProps.item"
          :index="slotProps.index"
          :readonly="!estadoPartePendiente"
          :has-perm-borrar="hasDeletePerm(permissions.parteTrabajoTiempoTrabajado.id)"
          @click-delete="clickDelete"
        />
      </template>
    </b10-list>
    <tiempo-trabajado-list-legend
      :showing.sync="showingDialogs.legends"
    />
    <b10-fab-button
      v-if="routeQuery.idparte_trabajo && estadoPartePendiente && !cronometroIdparte"
      :icon="$vuetify.icons.values.cronometro"
      left
      @click="clickIniciarCronometro"
    />
    <b10-fab-button
      v-if="routeQuery.idparte_trabajo && estadoPartePendiente && cronometroIdparte"
      :icon="$vuetify.icons.values.cronometroDetener"
      left
      @click="clickDetenerCronometro"
    />
    <b10-fab-button
      v-if="canAdd"
      @click="clickAdd"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './TiempoTrabajadoListData'
import TiempoTrabajadoListItem from './components/TiempoTrabajadoListItem'
import TiempoTrabajadoListLegend from './components/TiempoTrabajadoListLegend'
import { PARTE_TRABAJO } from '@/utils/consts'
import { get } from 'vuex-pathify'

const pageStoreName = 'pagesOfflineTiempoTrabajadoList'

export default {
  components: {
    TiempoTrabajadoListItem, TiempoTrabajadoListLegend,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info'
        },
      },
      showingDialogs: {
        legends: false
      },
      parteTrabajo: {},
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    cronometroIdparte: get('cronometro/idparte'),
    estadoPartePendiente () {
      return (
        this.parteTrabajo &&
        this.parteTrabajo.parte_trabajo &&
        this.parteTrabajo.parte_trabajo.estado === PARTE_TRABAJO.estados.pendiente
      )
    },
    canAdd () {
      return (
        this.routeQuery.idparte_trabajo &&
        this.estadoPartePendiente &&
        !this.cronometroIdparte &&
        this.hasViewPerm(this.permissions.tiempos.introducirManual) &&
        this.hasInsertPerm(this.permissions.parteTrabajoTiempoTrabajado.id))
    }
  },
  async created () {
    // routeQuery.idparte_trabajo: filtro por parte de trabajo
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Tiempo trabajado'
      if (this.routeQuery.idparte_trabajo) {
        this.parteTrabajo = await this.$offline.parteTrabajo.row(this.routeQuery.idparte_trabajo)
      }
      if (this.cronometroIdparte) {
        this.$alert.showSnackbarWarning(
          `Tienes el cronómetro inciado para el parte de trabajo Nº${this.cronometroIdparte}`
        )
      }
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectTiempoTrabajado(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeQuery.idparte_trabajo
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        await this.reloadItemsBase(
          await Data.selectTiempoTrabajadoRows(this, dirty.modified), dirty, 'tiempo_trabajado.idtiempo_trabajado'
        )
      } finally {
        this.loadingData = false
      }
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      }
    },
    clickListItem (item) {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'offline__tiempo-trabajado-view',
        params: {
          idtiempo_trabajado: item.data.tiempo_trabajado.idtiempo_trabajado
        }
      })
    },
    clickIniciarCronometro () {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'offline__cronometro-iniciar',
        params: {
          idparte_trabajo: this.routeQuery.idparte_trabajo,
        },
      })
    },
    clickDetenerCronometro () {
      // sin remember porque agrega un registro (igual que add)
      this.$appRouter.push({
        name: 'offline__cronometro-detener',
        params: {
          idparte_trabajo: this.routeQuery.idparte_trabajo,
        },
      })
    },
    clickAdd () {
      // sin remember porque agrega un registro
      this.$appRouter.push({
        name: 'offline__tiempo-add',
        params: {
          idparte_trabajo: this.routeQuery.idparte_trabajo,
        }
      })
    },
    async clickDelete (data) {
      const res = await this.$alert.showConfirm(
        `¿Deseas eliminar el tiempo trabajado de "${data.item.empleado.nombre_y_apellido}"?`
      )
      if (res) {
        this.$loading.showManual('Eliminando...')
        try {
          await this.$offline.tiempoTrabajado.deleteSync(data.item.tiempo_trabajado.idtiempo_trabajado)
          await this.dispatchStore('deleteItem', data.index)
        } finally {
          this.$loading.hide()
        }
      }
    },
  },
}
</script>
